.p--2 {
  padding: var(--size--2);
}

.p--1 {
  padding: var(--size--1);
}

.p-0 {
  padding: var(--size-0);
}

.p-1 {
  padding: var(--size-1);
}

.p-2 {
  padding: var(--size-2);
}

.p-3 {
  padding: var(--size-3);
}

.p-4 {
  padding: var(--size-4);
}

/**
 * Horizontal
 */

.px--2 {
  padding-inline: var(--size--2);
}

.px--1 {
  padding-inline: var(--size--1);
}

.px-0 {
  padding-inline: var(--size-0);
}

.px-1 {
  padding-inline: var(--size-1);
}

.px-2 {
  padding-inline: var(--size-2);
}

.px-3 {
  padding-inline: var(--size-3);
}

.px-4 {
  padding-inline: var(--size-4);
}

/**
 * Left
 */

.pl--2 {
  padding-inline-start: var(--size--2);
}

.pl--1 {
  padding-inline-start: var(--size--1);
}

.pl-0 {
  padding-inline-start: var(--size-0);
}

.pl-1 {
  padding-inline-start: var(--size-1);
}

.pl-2 {
  padding-inline-start: var(--size-2);
}

.pl-3 {
  padding-inline-start: var(--size-3);
}

.pl-4 {
  padding-inline-start: var(--size-4);
}

/**
 * Right
 */

.pr--2 {
  padding-inline-end: var(--size--2);
}

.pr--1 {
  padding-inline-end: var(--size--1);
}

.pr-0 {
  padding-inline-end: var(--size-0);
}

.pr-1 {
  padding-inline-end: var(--size-1);
}

.pr-2 {
  padding-inline-end: var(--size-2);
}

.pr-3 {
  padding-inline-end: var(--size-3);
}

.pr-4 {
  padding-inline-end: var(--size-4);
}

/**
 * Vertical
 */

.py--2 {
  padding-block: var(--size--2);
}

.py--1 {
  padding-block: var(--size--1);
}

.py-0 {
  padding-block: var(--size-0);
}

.py-1 {
  padding-block: var(--size-1);
}

.py-2 {
  padding-block: var(--size-2);
}

.py-3 {
  padding-block: var(--size-3);
}

.py-4 {
  padding-block: var(--size-4);
}

/**
 * Top
 */

.pt--2 {
  padding-block-start: var(--size--2);
}

.pt--1 {
  padding-block-start: var(--size--1);
}

.pt-0 {
  padding-block-start: var(--size-0);
}

.pt-1 {
  padding-block-start: var(--size-1);
}

.pt-2 {
  padding-block-start: var(--size-2);
}

.pt-3 {
  padding-block-start: var(--size-3);
}

.pt-4 {
  padding-block-start: var(--size-4);
}

/**
 * Bottom
 */

.pb--2 {
  padding-block-end: var(--size--2);
}

.pb--1 {
  padding-block-end: var(--size--1);
}

.pb-0 {
  padding-block-end: var(--size-0);
}

.pb-1 {
  padding-block-end: var(--size-1);
}

.pb-2 {
  padding-block-end: var(--size-2);
}

.pb-3 {
  padding-block-end: var(--size-3);
}

.pb-4 {
  padding-block-end: var(--size-4);
}
