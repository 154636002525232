.fz--2 {
  font-size: var(--size--2);
}

.fz--1 {
  font-size: var(--size--1);
}

.fz-0 {
  font-size: var(--size-0);
}

.fz-1 {
  font-size: var(--size-1);
}

.fz-2 {
  font-size: var(--size-2);
}

.fz-3 {
  font-size: var(--size-3);
}

.fz-4 {
  font-size: var(--size-4);
}

